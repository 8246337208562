import { gql } from 'Utils/apolloClient'
import ReservationDataFragment from 'Shared/Queries/ReservationFragment'

const reservationQuery = gql`
  query ReservationQuery ($slug: String!, $uuid: ID!, $isEngineAdmin: Boolean) {
    campground(slug: $slug, isEngineAdmin: $isEngineAdmin) {
      id
      name
      hasCancellationPolicyConfig
      reservation(uuid: $uuid) {
        ...reservationData
        balance {
          refunded {
            amount
            display
          }
        }
        balanceDue {
          amount
          display
        }
        balancePaid {
          amount
          display
        }
        balanceRemaining {
          amount
          display
        }
        balanceTotal {
          amount
          displayWithCode
        }
        cancellationPolicyConfig {
          fullDays
          fullDaysText
          halfDays
          halfDaysText
          id
          name
          noneDaysText
          noneDays
          refundApplicable
        }
        canReversePayout
        canShowWhatToKnow
        code
        stripeCardOnFile {
          ... on PaymentMethodStripeAch {
            id
            lastFour
          }
          ... on PaymentMethodStripeCard {
            id
            lastFour
          }
          __typename
        }
        hasBalance
        hasRefundsFailed
        hasRefundsPending
        installmentFirst {
          hasPendingRefunds
          id
          pricing {
            camperFeeMaxToRefund {
              amount
              display
            }
            camperProcessingFee {
              display
            }
            camperProcessingFeeMaxToRefund {
              amount
              display
            }
            grandTotalMax {
                amount
                display
              }
            isTaxIncluded
            reversePayoutAmountMax {
              amount
              display
            }
            subtotal {
              display
            }
            suggestedRefundAmount
          }
        }
        installmentDraft {
          amount {
            display
          }
          hasPendingRefunds
          id
          links {
            installmentPath
          }
          platformFee {
            display
          }
          processingFeeTotal {
            display
          }
          scheduledOn
          subtotal
          tax {
            display
          }
          total {
            display
          }
        }
        hasCancellationPolicyConfig
        paymentInitial {
          id
          amount {
            display
          }
          total {
            displayWithCode
          }
          totalRefunded {
            amount
            displayWithCode
          }
          totalRemaining {
            displayWithCode
          }
        }
        installmentsPaid {
          amount {
            display
          }
          campground {
            name
          }
          hasPendingRefunds
          id
          isExternallyPaid
          items {
            id
            subtotal {
              amount
              display
            }
            name
            total {
              display
            }
          }
          number
          paymentMethod {
            ... on PaymentMethodStripeAch {
              brand
              lastFour
            }
            ... on PaymentMethodStripeCard {
              brandIconKey
              lastFour
            }
            __typename
          }
          paymentSource
          platformFee {
            amount
            display
          }
          links {
            installmentPath
          }
          pricing {
            camperFee {
              display
            }
            camperFeeMaxToRefund {
              amount
              display
            }
            camperProcessingFee {
              display
            }
            camperProcessingFeeMaxToRefund {
              amount
              display
            }
            grandTotalMax {
                amount
                display
              }
            maxRefundableAmount {
              amount
              display
            }
            reversePayoutAmountMax {
              amount
              display
            }
            subtotal {
              display
            }
            tax {
              display
            }
          }
          processingFeeTotal {
            amount
            display
          }
          refunds {
            createdAt
            id
            state
            total {
              display
            }
          }
          refundsFailed {
            id
            failureReason
          }
          tax {
            display
          }
          total {
            display
            displayWithCode
          }
          totalRefunded {
            amount
            display
          }
          totalRemaining {
            display
          }
        }
        isDayUse
        nights
        pricing {
          camperFeeMaxToRefund {
            amount
            display
          }
          camperProcessingFeeMaxToRefund {
            amount
            display
          }
          currencyCode
          grandTotalMax {
            amount
            display
          }
          items {
            name
            units
            id
            price
          }
          nightlyTotal {
            amount
            display
          }
          maxRefundableAmount {
            display
          }
          nonRefundableAmount {
            display
            amount
          }
          reversePayoutAmountMax {
            amount
            display
          }
          totalRefunded {
            amount
            displayWithCode
          }
          suggestedRefundAmount
        }
        purchasePaymentExternal {
          sourceValue
        }
      }
    }
  }

  ${ReservationDataFragment}
`

export default reservationQuery
